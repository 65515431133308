
import {
	defineComponent,
	onMounted,
	reactive,
	ref,
	readonly,
	getCurrentInstance
} from 'vue'
import { useStore } from '../store'
import AdminLayout from '../global-components/layouts/AdminLayout.vue'
import mixpanel from 'mixpanel-browser'
import * as mp from '../apis/mixpanel'
import { PeopleProperty } from '@/types/mixpanel'

export default defineComponent({
	name: 'SimplePayLoginView',
	components: {
		AdminLayout
	},
	setup() {
		const store = useStore()
		const loginError = ref(false)
		const simplePayAdminLoginForm = ref(null)
		const distinctId = ref('')
		const emailForm = ref('')
		const formData = reactive({
			username: '',
			password: ''
		})
		const app = getCurrentInstance()

		const formValidationRules = readonly({
			username: [
				{
					required: true,
					message: 'Please include your username',
					trigger: 'blur'
				}
			],
			password: [
				{
					required: true,
					message: 'Please include your password',
					trigger: 'blur'
				}
			]
		})

		onMounted(() => {
			store.dispatch('hideLoadingScreen')
			distinctId.value =
				app.appContext.config.globalProperties.$distinct_id
			mixpanel.identify(distinctId.value)
			emailForm.value = app.appContext.config.globalProperties.$formEmail
		})

		async function login() {
			const isFormValid = simplePayAdminLoginForm.value.validate()

			if (!isFormValid) {
				console.log('failed validation')
				return
			}

			store.dispatch('showLoadingScreen')

			const credentials = {
				username: formData.username,
				password: formData.password
			}

			try {
				mp.loginPageViewTrack()

				await store.dispatch('logIn', credentials)
				if (credentials.username) {
					const userData: PeopleProperty = {
						admin_username: credentials.username,
						admin_user: true,
						name: credentials.username
					}
					mp.loginSetPeopleProp(userData)
					await store.dispatch('setUserName', credentials.username)
				}
			} catch {
				loginError.value = true
			} finally {
				store.dispatch('hideLoadingScreen')
			}
		}

		return {
			formData,
			formValidationRules,
			login,
			loginError,
			simplePayAdminLoginForm
		}
	}
})
