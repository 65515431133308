
import { computed, defineComponent } from 'vue'
import { useStore } from '../../store'

export default defineComponent({
	name: 'SimplePayHeader',
	setup() {
		const store = useStore()
		const iqProLogo = computed((): string => {
			return store.state.logos.iqProLogoNavy
		})
		const loggedIn = computed((): boolean => {
			return store.state.isLoggedIn
		})
		function logout(): void {
			store.dispatch('logOut')
		}

		return {
			iqProLogo,
			loggedIn,
			logout
		}
	}
})
