<template>
	<el-header height="54px">
		<div class="header-container">
			<img :src="iqProLogo" alt="IQ Pro logo" />
			<!-- <a href="#" class="logout-btn" @click="logout" v-show="this.loggedIn"
			>Logout</a
		> -->
			<el-button
				v-show="loggedIn"
				ref="logoutButton"
				class="logout-button"
				size="mini"
				type="info"
				@click="logout"
				>Log Out</el-button
			>
		</div>
	</el-header>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from '../../store'

export default defineComponent({
	name: 'SimplePayHeader',
	setup() {
		const store = useStore()
		const iqProLogo = computed((): string => {
			return store.state.logos.iqProLogoNavy
		})
		const loggedIn = computed((): boolean => {
			return store.state.isLoggedIn
		})
		function logout(): void {
			store.dispatch('logOut')
		}

		return {
			iqProLogo,
			loggedIn,
			logout
		}
	}
})
</script>

<style lang="scss" scoped>
header {
	background: $navy;
	width: 100vw;
	box-shadow: 0px -3px 6px 3px $shadow-gray;

	:deep(.header-container) {
		max-width: 800px;
		margin: auto;
	}

	:deep(img) {
		height: 34px;
		margin: 10px 0;
	}

	:deep(.logout-button) {
		float: right;
		margin: 12px 0;
		padding: 0 16px;

		&:hover,
		&:active,
		&:focus {
			background: #9b9ea1;
			opacity: 1;
		}
	}
}
</style>
