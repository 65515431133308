<template>
	<div class="view-wrapper">
		<AdminHeader />
		<slot />
		<transition name="fade">
			<LoadingOverlay v-show="isLoading" />
		</transition>
		<AdminFooter />
	</div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue'
import { useStore } from '../../store'
import AdminHeader from '../headers/AdminHeader.vue'
import AdminFooter from '../footers/AdminFooter.vue'
import LoadingOverlay from '../LoadingOverlay.vue'

export default defineComponent({
	name: 'AdminLayout',
	components: {
		AdminHeader,
		AdminFooter,
		LoadingOverlay
	},
	setup() {
		const store = useStore()
		const isLoading = computed((): boolean => store.state.isLoading)

		onMounted(() => {
			store.dispatch('checkExistingLogin')
		})

		return { isLoading }
	}
})
</script>

<style lang="scss" scoped>
.view-wrapper {
	background: $aqua-gradient;
}
</style>
