<template>
	<admin-layout>
		<el-form
			ref="simplePayAdminLoginForm"
			label-position="top"
			:model="formData"
			:rules="formValidationRules"
			size="small"
		>
			<el-main class="login-form">
				<el-row :gutter="0">
					<el-col :span="20" :offset="2">
						<el-alert
							v-show="loginError"
							title="Login failed, please try again."
							type="error"
							class="mb-20"
						></el-alert>
					</el-col>
				</el-row>
				<div class="input-group" data-login>
					<el-row :gutter="0">
						<el-col :span="20" :offset="2">
							<el-form-item label="Username" prop="username">
								<el-input
									id="username"
									v-model="formData.username"
									type="text"
									data-cy="username"
								></el-input>
							</el-form-item>
							<el-form-item label="Password" prop="password">
								<el-input
									id="password"
									v-model="formData.password"
									type="password"
									data-cy="password"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="0">
						<el-col :span="6" :offset="16">
							<el-form-item class="mt-5 mb-0">
								<el-button
									class="payment-button"
									type="primary"
									data-cy="login-button"
									:disabled="
										formData.username === '' ||
										formData.password === ''
									"
									@click="login"
									>Log In</el-button
								>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
			</el-main>
		</el-form>
	</admin-layout>
</template>

<script lang="ts">
import {
	defineComponent,
	onMounted,
	reactive,
	ref,
	readonly,
	getCurrentInstance
} from 'vue'
import { useStore } from '../store'
import AdminLayout from '../global-components/layouts/AdminLayout.vue'
import mixpanel from 'mixpanel-browser'
import * as mp from '../apis/mixpanel'
import { PeopleProperty } from '@/types/mixpanel'

export default defineComponent({
	name: 'SimplePayLoginView',
	components: {
		AdminLayout
	},
	setup() {
		const store = useStore()
		const loginError = ref(false)
		const simplePayAdminLoginForm = ref(null)
		const distinctId = ref('')
		const emailForm = ref('')
		const formData = reactive({
			username: '',
			password: ''
		})
		const app = getCurrentInstance()

		const formValidationRules = readonly({
			username: [
				{
					required: true,
					message: 'Please include your username',
					trigger: 'blur'
				}
			],
			password: [
				{
					required: true,
					message: 'Please include your password',
					trigger: 'blur'
				}
			]
		})

		onMounted(() => {
			store.dispatch('hideLoadingScreen')
			distinctId.value =
				app.appContext.config.globalProperties.$distinct_id
			mixpanel.identify(distinctId.value)
			emailForm.value = app.appContext.config.globalProperties.$formEmail
		})

		async function login() {
			const isFormValid = simplePayAdminLoginForm.value.validate()

			if (!isFormValid) {
				console.log('failed validation')
				return
			}

			store.dispatch('showLoadingScreen')

			const credentials = {
				username: formData.username,
				password: formData.password
			}

			try {
				mp.loginPageViewTrack()

				await store.dispatch('logIn', credentials)
				if (credentials.username) {
					const userData: PeopleProperty = {
						admin_username: credentials.username,
						admin_user: true,
						name: credentials.username
					}
					mp.loginSetPeopleProp(userData)
					await store.dispatch('setUserName', credentials.username)
				}
			} catch {
				loginError.value = true
			} finally {
				store.dispatch('hideLoadingScreen')
			}
		}

		return {
			formData,
			formValidationRules,
			login,
			loginError,
			simplePayAdminLoginForm
		}
	}
})
</script>

<style lang="scss" scoped>
.login-form {
	width: calc(100vw - 30px);
	max-width: 400px;
	height: auto;
	padding: 35px 0px;
	margin: auto;
	margin-top: 0;
	position: relative;
	top: 100vh;
	transform: translateY(-80vh);
	z-index: 2;
	background: #fff;
	color: #464646;
	border-radius: 5px;
	box-shadow: 1px 1px 3px 0px $shadow-gray;

	@media screen and (max-width: 480px) {
		margin-top: 15px;
		padding: 15px 25px;
	}

	@media screen and (max-width: 380px) {
		transform: translateY(-90vh);
	}
}
</style>
